@font-face {
    font-family: 'Sharp Sans Light';
    src: local('Sharp Sans Light'), url(./fonts/Sharp\ Sans\ Light.otf) format('opentype');
}
  
@import url('https://fonts.googleapis.com/css2?family=Amethysta&family=Permanent+Marker&family=Questrial&display=swap');

.questrial-regular {
  font-family: "Questrial", serif;
  font-weight: 400;
  font-style: normal;
}

html {
    color: white;
    cursor: url('./amiga.cur'), auto;
}

body {
    margin: 0;
    padding: 0;
    background-color: #000;
    font-family: "Inconsolata", monospace;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
    font-variation-settings:
      "wdth" 100;
}

.black-btn {
    padding: 10px;
    background-color: #000;
    color: white;
    border: 1px solid white;
    cursor: pointer;
    margin-bottom: 20px;
}

.sd {
    width: 30px;
    height: 30px;
    padding-top: 5px;
}

.header-container {
    padding-left: 20px;
    padding-top: 14px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
  max-width: 300px;
}

.heading {
    padding-left: 5px;
    padding-bottom: 3px;
    font-weight: 500;
    margin: 0;
    font-size: 16px;
    color: #fffcf9;
    user-select: none;
    cursor: url('./amiga.cur'), auto;
}

.rainbow-line {
    height: 1px; /* Adjust this to change the thickness of the line */
    background: linear-gradient(270deg, #FF7F7F, #FFBF7F, #FFFF7F, #7FFF7F, #7FBFFF, #7F7FFF, #BF7FFF);    background-size: 1400% 1400%;
    animation: rainbow 20s ease-in-out infinite;
  }
  
  @keyframes rainbow {
    0%{background-position:0% 82%}
    50%{background-position:100% 19%}
    100%{background-position:0% 82%}
  }

.icon-transition div {
    transition: opacity 0.3s ease-in-out;
    position: absolute;
}

.icon-transition .hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  .icon-transition div:not(.hidden) {
    opacity: 1;
  }

  .section {
    padding: 0;
    margin: 0;
    font-size: 16px;
    font-weight: 300;
  }

  .album-covers {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    box-sizing: border-box;
    padding-top: 10px;
  }

  .album-cover {
    background-size: cover;
    background-position: center;
    height: 0;
    padding-bottom: 100%;
    position: relative;
}

.large-cover {
  background-size: cover;
  background-position: center;
  height: 350px;
  width: 350px;
}

.main-container {
  height: calc(100vh - 180px);
}

.promo-cover-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh - 300px);
  flex-direction: column;
}

.sticky-header {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #000;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.accordian-row {
    display: flex;
    align-items: center;
}

.accordian-row-container {
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.arrow-icon {
  padding-left: 16px;
}

.album-cover {
  transition: transform 0.2s ease-in-out;
}

.album-cover:hover {
  transform: scale(1.05);
}

.release-info {
  box-sizing: border-box;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  padding: 10px;
}

.play-button {
  position: absolute;
  top: 20px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;  
}

.release-info p {
  padding: 0;
  margin: 0;
}

.footer {
  padding: 20px;
}

a {
  color: white;
}

.nav-link {
  padding: 10px;
  padding-bottom: 15px;
  padding-right: 0px;
  user-select: none;
}

.contact-form {
  padding: 20px;
  padding-top: 20px;
}

form {
  display: flex;
  flex-direction: column;
  max-width: 300px;
}

form label {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

form label input, textarea {
  margin-top: 10px;
}

input {
  padding: 10px;
}

textarea {
  padding: 10px;
  height: 200px;
}

.message-sent {
  padding-top: 10px;
  color: #7FFF7F;
}

.message-failed {
  padding-top: 10px;
  color: tomato;
  
}

.contact-header {
  margin: 0;
  font-weight: 500;
  padding-bottom: 10px;
}

.email-list {
  padding-left: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  font-size: larger;
  color:#7FFF7F;
}

.youtube-container {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 250px;
  width: 250px;
}

.hidden {
  display: none;
}

.youtube-close {
  position: absolute;
  top: -20px;
  left: -20px;
  padding: 10px;
  cursor: pointer;
}

.home-copy {
  padding: 20px;
}

.canvas {
    height: 80vh; /* Adjust this value as needed */
    width: 100%;
}

.mixtape-container {
  padding-top: 10px;
}


/* Download Manager */
.download-manager {
  position: fixed; 
  z-index: 101; 
  left: 50%;
  top: 30%;
  transform: translate(-50%, -50%);
  overflow: auto;
  background-color: white;
  color: black;
  padding: 10px;
  border: 2px solid #000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  background-color: #c0c0c0;
  border-radius: 1px;
  padding: 20px;

  @media (max-width: 600px) {
    left: 10px;
    right: 10px;
    margin-left: auto;
    margin-right: auto;
    transform: translate(0, -50%);
  }

}

.backdrop {
  position: fixed;
  z-index: 101;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
}

.activation-key-input {
  padding: 6px;
  text-transform: uppercase;
  width: 5ch;
  font-family: monospace;
  border-radius: 0;
  border: solid 1px black;
}

.hyphen {
  padding: 0 5px;
  font-size: 20px;
  font-weight: bold;
}

.label {
  margin-bottom: 10px;
}

@keyframes shake {
  0% { transform: translate(calc(-50% + 0.5px), calc(-50% + 0.5px)) rotate(0deg); }
  10% { transform: translate(calc(-50% - 0.5px), calc(-50% - 1px)) rotate(-1deg); }
  20% { transform: translate(calc(-50% - 1px), -50%) rotate(1deg); }
  30% { transform: translate(calc(-50% + 1px), calc(-50% + 1px)) rotate(0deg); }
  40% { transform: translate(calc(-50% + 0.5px), calc(-50% - 0.5px)) rotate(1deg); }
  50% { transform: translate(calc(-50% - 0.5px), calc(-50% + 1px)) rotate(-1deg); }
  60% { transform: translate(calc(-50% - 1px), calc(-50% + 0.5px)) rotate(0deg); }
  70% { transform: translate(calc(-50% + 1px), calc(-50% + 0.5px)) rotate(-1deg); }
  80% { transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px)) rotate(1deg); }
  90% { transform: translate(calc(-50% + 0.5px), calc(-50% + 1px)) rotate(0deg); }
  100% { transform: translate(calc(-50% + 0.5px), calc(-50% - 1px)) rotate(-1deg); }
}

@keyframes shake-small {
  0% { transform: translate(0, calc(-50% + 0.5px)) rotate(0deg); }
  10% { transform: translate(0, calc(-50% - 1px)) rotate(-1deg); }
  20% { transform: translate(0, -50%) rotate(1deg); }
  30% { transform: translate(0, calc(-50% + 1px)) rotate(0deg); }
  40% { transform: translate(0, calc(-50% - 0.5px)) rotate(1deg); }
  50% { transform: translate(0, calc(-50% + 1px)) rotate(-1deg); }
  60% { transform: translate(0, calc(-50% + 0.5px)) rotate(0deg); }
  70% { transform: translate(0, calc(-50% + 0.5px)) rotate(-1deg); }
  80% { transform: translate(0, calc(-50% - 0.5px)) rotate(1deg); }
  90% { transform: translate(0, calc(-50% + 1px)) rotate(0deg); }
  100% { transform: translate(0, calc(-50% - 1px)) rotate(-1deg); }
}

.shake {
  animation: shake 0.05s;
  animation-iteration-count: 10;

  @media (max-width: 600px) {
    animation: shake-small 0.05s;
    animation-iteration-count: 10;
  }
}

.media {
  max-width: 800px;
}

.close-button {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.post-image {
  width: 100%;
  height: 100%;
}

.post-video {
  width: 100%;
  height: 100%;
}